import { PageSlot } from "@portal-frontend-ssr/ui";
import { json, MetaFunction } from "@remix-run/node";
import { Background } from "../_cs.counter-strikle.multiplayer._index/components/Background";
import logoImg from "./assets/big-logo.svg";
import { Link } from "@remix-run/react";
import newGif from "./assets/new2x.webp";
import { getConfig } from "@portal-frontend-ssr/config";
import { generateMetaTags } from "~/shared/helpers/metaTags/generateMetaTags";
import { StickySideRailAds } from "~/shared/components/StickySideRailAds";

export async function loader() {
  return json({});
}

export const meta: MetaFunction<typeof loader> = () => {
  const baseAssetsURL = getConfig().BASE_ASSET_URL;

  const metaImageUrl = `${baseAssetsURL}/images/counter-strikle/landing-seo-image.webp?width=1280`;

  return generateMetaTags({
    metaTitle: "Counter-Strikle - The home of the CS Wordle",
    metaDescription:
      "Put your CS knowledge to test in a Wordle-like game. Play daily solo, or challenge others in multiplayer!",
    metaImageUrl,
  });
};

export const Component = () => {
  return (
    <>
      <StickySideRailAds />
      <PageSlot className="m-nav min-h-fill-available pt-3 md:pt-10">
        <Background />
        <div className="mx-auto flex h-full flex-col items-center justify-center gap-9 overflow-y-hidden">
          <img src={logoImg} alt="Counter Strikle Logo" className="w-full max-w-64" />
          <span className="font-style-body-b2 md:font-style-body-b1">Guess the mystery player</span>
          <div className="flex flex-col gap-3 md:flex-row md:gap-5">
            <a href="/counter-strikle/daily" className="button w-44">
              <span>Daily</span>
            </a>
            <Link to="/counter-strikle/multiplayer" className="button-secondary relative w-44">
              <span>Multiplayer</span>
              <img src={newGif} alt="New" className="absolute right-2 top-1.5 h-2.5" />
            </Link>
          </div>
        </div>
      </PageSlot>
    </>
  );
};

export default Component;
